import { Component } from '@angular/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PoRestService } from '../po-rest.service';

@Component({
  selector: 'app-po-list',
  templateUrl: 'po-list.component.html'
})
export class PoListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'PO Number',
      field: 'poNumber'
    },
    {
      i18nLabel: 'Order Date',
      field: 'createdAt'
    },
    {
      i18nLabel: 'Name ',
      field: 'name'
    },
    {
      i18nLabel: 'No Handphone',
      field: 'phoneNo'
    },
    {
      i18nLabel: 'Email',
      field: 'email'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _poRest: PoRestService) {
    super();
    this.componentId = 'PoListComponent';
    this.headerTitle = 'Data Po';
    this.exportFileName = 'roles';
    this.exportDocumentTitle = 'ui.role.list.title';
    // this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.role.delete';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',

      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._poRest.findAll(qParams).pipe(
          switchMap((doc: any) => {
            const dataFormatted = doc?.data.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM, YYYY');

              return d;
            });

            doc.data = dataFormatted;

            return of(doc);
          })
        );
      },

      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const { _id } = event.data;
        return this._poRest.delete(_id);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'PoListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}

import { AfterViewInit, EventEmitter, forwardRef, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as SnackBar from 'node-snackbar';
import { Observable, of, Subscription, throwError, forkJoin, zip, merge } from 'rxjs';
import { catchError, tap, switchMap, concatAll } from 'rxjs/operators';
import swal from 'sweetalert2';
import { InjectorService } from '../../../libraries/common/injector.service';
import { ObservableService } from '../../../libraries/common/observable.service';
import { MCompHookHookItem } from '../../../libraries/interaction/comp-hook.model';
import { AccountingService } from '../../../libraries/math/accounting.service';
import { SpinnerService } from '../../../libraries/spinner/spinner.service';
import { AclService } from '../../auth/acl.service';
import { CentralCacheStorage } from '../../interaction/central-cache.model';
import { CentralCacheService } from '../../interaction/central-cache.service';
import { MHookEventBeforeCall, MHookEventFailed, MHookEventSuccess, MHookItem } from '../../interaction/hook.model';
import { BasePageComponent } from '../page/base-page.component';
import { BaseCompComponent } from './base-comp.component';
import { MBaseCompHeaderActionButton } from './base-comp.model';

export class BaseCompBComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(forwardRef(() => BaseCompComponent), { static: true }) elComp: BaseCompComponent;
  @Output() compReady: EventEmitter<boolean> | boolean = new EventEmitter();

  public accountingService = InjectorService.get<AccountingService>(AccountingService);
  public centralCacheService = InjectorService.get<CentralCacheService>(CentralCacheService);
  public ngZone = InjectorService.get<NgZone>(NgZone);
  public observableService = InjectorService.get<ObservableService>(ObservableService);
  public spinnerService = InjectorService.get<SpinnerService>(SpinnerService);
  public aclService = InjectorService.get<AclService>(AclService);

  get page(): BasePageComponent {
    if (!this.elComp.page) {
      console.error(`@Page is not defined, you should move your logic after ngAfterViewInit and don't forget to use app-base-comp component!`);
    }
    return this.elComp.page;
  }

  headerButtons: MBaseCompHeaderActionButton[] = [];
  headerTitle: string;

  deleteRecordI18nLabel: string;
  deleteRecordI18nDescription: string;
  deleteRecordI18nSuccess: string;

  toggleActiveRecordI18nLabel: string;
  toggleActiveRecordI18nDescription: string;
  toggleActiveRecordI18nSuccess: string;
  toggleInactiveRecordI18nLabel: string;
  toggleInactiveRecordI18nDescription: string;
  toggleInactiveRecordI18nSuccess: string;

  private _componentId: string;
  get componentId() {
    return this._componentId;
  }

  set componentId(componentId) {
    this._componentId = componentId;
  }

  routeURL: string;

  initializingComp: boolean = false;
  compInitialized: boolean = false;

  public elementsVisibility: { [key: string]: boolean } = {};
  public keyValueStorage: any = {};

  hookStates: { hookId: string, result?: any; error?: any; }[] = [];

  registerHook: (hook: MCompHookHookItem) => void;
  hasHook: (hookId: string) => boolean;
  callHook: (hookId: string, hookData?: any) => Observable<any>;
  callHookDirectly: (hookId: string, hookData?: any) => Subscription;
  onHookBeforeCallByName: (hookId: string) => Observable<MHookEventBeforeCall<MHookItem>>;
  onHookBeforeSuccessByName: (hookId: string) => Observable<MHookEventSuccess<MHookItem>>;
  onHookSuccessByName: (hookId: string) => Observable<MHookEventSuccess<MHookItem>>;
  onHookFailedByName: (hookId: string) => Observable<MHookEventFailed<MHookItem>>;

  private registeredSubscriptions: Subscription[] = [];

  get comp(): BaseCompComponent {
    return this.elComp;
  }

  get hookStatesIsValid(): boolean {
    return this.hookStates.filter(hookState => hookState.error).length > 0;
  }

  get isValid(): boolean {
    return this.compInitialized && this.hookStatesIsValid;
  }

  setHookState(state: MHookEventSuccess<MHookItem> | MHookEventFailed<MHookItem>) {
    const existingStateIndex = this.hookStates.findIndex(hookState => hookState.hookId === state.hook.hookId);
    if (existingStateIndex > -1) {
      this.hookStates.splice(existingStateIndex, 1);
    }

    this.hookStates.push({
      hookId: state.hook.hookId,
      result: (<MHookEventSuccess<MHookItem>>state).result,
      error: (<MHookEventFailed<MHookItem>>state).error,
    });
  }

  ngOnInit() {
    this.assignCompatibilityLayer();
  }

  ngAfterViewInit() {
    this.page.registerRouterChildComponent(this);

    this.initialize();
  }

  ngOnDestroy() {
    this.registeredSubscriptions.forEach(registeredSubscription => registeredSubscription.unsubscribe());
  }

  appDefineFixedHooks() {
  }

  initialize() {
    this.appInit().subscribe(() => {
      this.comp._changeDetectorRef.detectChanges();
    });
  }

  // this method will be called when initComp (in component) success
  public appOnInit() {
  }

  // this method will be called when initComp (in component) error
  public appOnInitError(error: any) {
  }

  private appInit() {
    this.initializingComp = true;
    const obs = this.getInitCompObservables();

    return obs.pipe(
      catchError(error => {
        this.initializingComp = false;
        this.appOnInitError(error);
        return throwError(error);
      }),

      switchMap(() =>
        forkJoin(
          of(
            this.observableService.convertFunctionToObservable(() => this.appDefineFixedHooks()),
            this.observableService.convertFunctionToObservable(() => this.appOnInit()),
          ).pipe(concatAll())
        )
      ),
      tap(() => {
        this.initializingComp = false;
        this.compInitialized = true;

        if (this.compReady instanceof EventEmitter) {
          this.compReady.emit(true);
        } else {
          this.compReady = true;
        }
      })
    );
  }

  // put all observables that need to be run.
  // can be override on child
  // but remember to call super and fork join with super observable
  // after success, isCompInitialized=true
  getInitCompObservables(): Observable<any> {
    return zip(
      this.comp._activatedRoute.data,
      this.comp._activatedRoute.params,
      this.comp._activatedRoute.queryParams
    ).pipe(
      tap(([routeData, routeParams, routeQueryParams]) => {
        this.comp.routeData = routeData;
        this.comp.routeParams = routeParams;
        this.comp.routeQueryParams = routeQueryParams;
      }));
  }

  registerSubscription(subscription: Subscription) {
    this.registeredSubscriptions.push(subscription);
  }

  ngForIdentifier(fieldTarget: string) {
    return (index: number, item: any) => {
      return _.get(item, fieldTarget);
    };
  }

  deleteRecord(record: any) {
    return this.callHook('deleteRecord', record).pipe(tap(() => {
      this.comp._globalSystemMessage.log({
        message: this.comp._translate.instant(this.deleteRecordI18nSuccess),
        type: 'success',
        showAs: 'growl',
        showSnackBar: false
      });
    }));
  }

  showDeleteRecordDialog() {
    return new Observable(observer => {
      swal.fire({
        title: this.comp._translate.instant(this.deleteRecordI18nLabel),
        text: this.comp._translate.instant(this.deleteRecordI18nDescription),
        icon: 'question',
        showCancelButton: true,
      }).then(result => {
        if (result.value) {
          observer.next(result);
          observer.complete();
        } else {
          observer.complete();
        }
      });
    });
  }

  toggleInactiveRecord(record: any, inactiveState: boolean) {
    const targetI18nSuccess = !inactiveState ? this.toggleActiveRecordI18nSuccess : this.toggleInactiveRecordI18nSuccess;

    return this.callHook('toggleInactiveRecord', { record, inactiveState })
      .pipe(
        tap(() => {
          SnackBar.show({
            text: this.comp._translate.instant(targetI18nSuccess),
            pos: 'bottom-left'
          });
        })
      );
  }

  showToggleInactiveRecordDialog(inactiveState: boolean) {
    const targetI18nLabel = !inactiveState ? this.toggleActiveRecordI18nLabel : this.toggleInactiveRecordI18nLabel;

    const targetI18nDescription = !inactiveState ? this.toggleActiveRecordI18nDescription : this.toggleInactiveRecordI18nDescription;
    return new Observable(observer => {
      swal.fire({
        title: this.comp._translate.instant(targetI18nLabel),
        text: this.comp._translate.instant(targetI18nDescription),
        icon: 'question',
        showCancelButton: true,
      }).then(result => {
        if (result.value) {
          observer.next(result);
          observer.complete();
        } else {
          observer.complete();
        }
      });
    });
  }

  observableWrapErrorMessage(observable: Observable<any>, messageConfig: any = {}) {
    return observable.pipe(
      catchError((error) => {
        let message = '';
        if (error && error.response) {
          message = error.response;
        }
        this.comp._systemMessage.log({
          message,
          type: 'error',
          ...messageConfig,
        });

        return throwError(error);
      })
    );
  }

  observableWrapCacheable(observable: Observable<any>, id: string) {
    const cacheId = `${this.componentId}:observable:${id}`;

    const cacheStorage = this.centralCacheService.registerCacheStorageIfNotExists(new CentralCacheStorage(cacheId));

    if (cacheStorage.data) {
      return of(cacheStorage.data);
    }

    return observable.pipe(
      tap((result) => {
        this.centralCacheService.setCacheData(cacheId, result);
      }),
    );
  }

  getHookIdByName(hookId: string) {
    return `${this.componentId}:${hookId}`;
  }

  private assignCompatibilityLayer() {
    if (this.page) {
      this.registerHook = (hook: MCompHookHookItem) => {
        hook.hookId = this.getHookIdByName(hook.hookName);
        hook.comp = () => this.elComp;
        this.page.compHookService.registerHook(hook);
      };

      this.hasHook = (hookName: string) => {
        return this.page.compHookService.hasHook(this.getHookIdByName(hookName));
      };

      this.callHook = (hookName: string, hookData?: any) => {
        return this.page.compHookService.callHookSafe(this.getHookIdByName(hookName), hookData);
      };

      this.callHookDirectly = (hookName: string, hookData?: any) => {
        return this.page.compHookService.callHookSafeDirectly(this.getHookIdByName(hookName), hookData);
      };

      this.onHookBeforeCallByName = (hookName: string) => {
        return this.page.compHookService.onHookBeforeCallById(this.getHookIdByName(hookName));
      };

      this.onHookBeforeSuccessByName = (hookName: string) => {
        return this.page.compHookService.onHookBeforeSuccessById(this.getHookIdByName(hookName));
      };

      this.onHookSuccessByName = (hookName: string) => {
        return this.page.compHookService.onHookSuccessById(this.getHookIdByName(hookName));
      };

      this.onHookFailedByName = (hookName: string) => {
        return this.page.compHookService.onHookFailedById(this.getHookIdByName(hookName));
      };


      merge(
        this.page.compHookService.onHookSuccessByComponentId(this.componentId),
        this.page.compHookService.onHookFailedByComponentId(this.componentId),
      ).subscribe(value => {
        this.setHookState(value);
      });
    } else {
      console.error('PAGE NOT FOUND, PLEASE ADD -- @Inject(forwardRef(() => BasePageComponent)) public page: BasePageComponent -- TO THE COMPONENT CONSTRUCTOR!!');
    }
  }
}



import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { NewsListComponent } from './list/news-categories-list.component';
import { NewsCreateComponent } from './create/news-categories-create.component';
import { NewsCategoriesUpdateComponent } from './update/news-categories-update.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: NewsListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'newsCategory.read'
            }
          }
        ]
      },
      {
        path: 'create',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: NewsCreateComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'newsCategory.create'
            }
          }
        ]
      },
      {
        path: ':id/update',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: NewsCategoriesUpdateComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'newsCategory.update'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsCategoriesRoutingModule {}

import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { TranslatorService } from '../../../libraries/common/translator.service';
import { AdminLayoutService } from '../admin-layout.service';

@Component({
  selector: 'app-admin-layout-header',
  templateUrl: 'admin-layout-header.component.html',
  styleUrls: ['admin-layout-header.component.scss', 'admin-layout-header.menu-links.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutHeaderComponent {
  // TRACK THIS COMPONENT

  sidebarVisible: true;
  sidebarOffcanvasVisible: boolean;

  navbarMenus: any;

  constructor(
    public _authentication: AuthenticationService,
    public _adminLayout: AdminLayoutService,
    public _translate: TranslateService,
    public _translator: TranslatorService,
  ) { }

  toggleSidebarOffcanvasVisible() {
    this._adminLayout.sidebar.offcanvasVisible = !this._adminLayout.sidebar.offcanvasVisible;
  }

  toggleSidebar(state) {
    //  state === true -> open
    //  state === false -> close
    //  state === undefined -> toggle
    this._adminLayout.sidebar.visible = typeof state !== 'undefined' ? state : !this._adminLayout.sidebar.visible;
  }
}

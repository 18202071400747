import { Component, ViewChild, ElementRef } from '@angular/core';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { FaqRestService } from '../faq-rest.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: 'faq-list.component.html'
})
export class FaqListComponent extends BaseListBComponent<any> {
  @ViewChild('tableColumnAnswer', { static: false })
  elTableColumnAnswer: ElementRef;
  tableColumnsShow: boolean;

  constructor(private _faqRest: FaqRestService) {
    super();
    this.componentId = 'FAQListComponent';
    this.headerTitle = 'ui.faq.list.title';
    this.exportFileName = 'faqs';
    this.exportDocumentTitle = 'ui.faq.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.faq.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.faq.list.button.create',
        color: 'primary-outline',
        routerLink: '/faq/create',
        permissions: ['faq.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._faqRest.findAll(qParams);
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const faqId = event.data._id;
        return this._faqRest.delete(faqId);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'FAQListComponent:workflowLoadData'
        ])
    });
  }

  prepareTable() {
    this.tableColumns = [
      {
        i18nLabel: 'ui.faq.entity.question',
        field: 'question'
      },
      {
        i18nLabel: 'ui.faq.entity.answer',
        field: 'answer',
        template: this.elTableColumnAnswer
      }
    ];

    this.tableColumnsShow = false;
    this.tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
      this.tableColumns
    );
  }

  appOnInit() {
    super.appOnInit();

    this.prepareTable();

    return this.callHook('init');
  }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { UserRestService } from '../user-rest.service';

@Component({
  selector: 'app-user-create',
  templateUrl: 'user-create.component.html'
})
export class UserCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(private _userRest: UserRestService) {
    super();
    this.componentId = 'UserCreateComponent';
    this.routeURL = '/users';
    this.entrySuccessI18n = 'success.user.create';
    this.headerTitle = 'ui.user.create.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;
        delete formValue.role

        return this._userRest.create(formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'UserFormComponent:workflowBuildForm'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}

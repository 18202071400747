import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ExportExcelService } from './excel/export-excel.service';
import { ExportPDFService } from './pdf/export-pdf.service';
import { ExportDataService } from './export-data.service';

@Injectable()
export class ExportDataTableService {
  exportLimit: number = 500;
  skipLimit: number = 0;
  constructor(
    private _exportExcelMap: ExportExcelService,
    private _exportPDFMap: ExportPDFService,
    private _exporter: ExportDataService,
    private _translate: TranslateService,
  ) { }

  async export(params: {
    extension: string,
    fileName: string,
    mapOptions: IExportDataTableMap,
    records: any[],
    templateData?: any,
    pdfOptions?: any
  }) {
    let blobContent = null;

    switch (params.extension) {
      case 'xls':
        blobContent = await this._exportExcelMap.getBlob(params);
        break;
      case 'pdf':
        blobContent = await this._exportPDFMap.getBlob(params);
        break;
    }

    this._exporter.download(blobContent, params.fileName, params.extension);
  }

  mapFromTableColumns(columns: IGridTableColumn[]): IExportDataTableMap {
    const results: IExportDataTableMap = {};
    _.forEach(columns, columnOption => {
      results[columnOption.field] = <any>{
        header: this._translate.instant(columnOption.i18nLabel),
        ..._.omit(columnOption, ['field', 'header'])
      };
    });
    return results;
  }
}

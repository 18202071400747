import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UrlService } from '../../libraries/common/url.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {
  sidebar: any = {
    over: false,
    showheader: true,
    showtoolbar: true,
    visible: false,
    offcanvas: true,
    offcanvasVisible: true
  };

  menuItems: any[] = [{
    i18nName: 'ui.navbarMenu.dashboard',
    iconClass: 'fa fa-tachometer-alt',
    link: '/dashboard',
    permissions: ['dashboard']
  },
  {
    i18nName: 'ui.navbarMenu.po',
    iconClass: 'fa fa-shopping-cart',
    link: '/po',
    permissions: ['order.read']
  },
  {
    i18nName: 'ui.navbarMenu.news',
    iconClass: 'fa fa-newspaper',
    link: '/news',
    permissions: ['news.read']
  },
  {
    i18nName: 'ui.navbarMenu.categories',
    iconClass: 'fa fa-list',
    link: '/news-categories',
    permissions: ['newsCategory.read']
  },
  {
    i18nName: 'ui.navbarMenu.faq',
    iconClass: 'fa fa-question',
    link: '/faq',
    permissions: ['faq.read']
  },
  {
    i18nName: 'ui.navbarMenu.location',
    iconClass: 'fa fa-map-marker-alt',
    link: '/location',
    permissions: ['location.read']
  },
  {
    i18nName: 'ui.navbarMenu.user',
    iconClass: 'fa fa-users',
    link: '/users',
    permissions: ['user.read']
  },
  {
    i18nName: 'ui.navbarMenu.role',
    iconClass: 'fa fa-users-cog',
    link: '/roles',
    permissions: ['role.read']
  }
  ];

  constructor(private _router: Router, private _url: UrlService) {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        const url = this._url.extractHashParts();
        if ((e as any).url !== url.urlPath) {
        }
      } else if (e instanceof NavigationEnd) {
        $('main.main-container').animate(
          {
            scrollTop: 0
          },
          0
        );
      }
    });
  }
}

import { Component, Input, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { APP_CONST } from '../../../app.const';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { MFormgenSchemaField } from '../../../libraries/formgen/formgen.model';
import { NewsRestService } from '../news-rest.service';

@Component({
  selector: 'app-news-form',
  templateUrl: 'news-form.component.html'
})
export class NewsFormComponent extends BaseFormBComponent {
  constructor(private _newsRest: NewsRestService) {
    super();

    this.componentId = 'NewsFormComponent';
  }

  uploadEndpoint = APP_CONST.API_FILE;
  ckEditorConfig = {
    fileBrowserUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Files&responseType=json`,
    fileBrowserImageUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Images&responseType=json`
  };

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            name: 'mainImageId',
            ignore: true
          },
          {
            name: 'newsCategoryId',
            ignore: true
          },
          {
            name: 'mainImage',
            ignore: true
          },
          {
            name: 'description',
            ignore: true
          },
          {
            label: 'ui.news.entity.title',
            name: 'title',
            field: 'text',
            validations: {
              required: true
            }
          },

          {
            name: 'newsCategory',
            field: 'autocomplete',
            label: 'ui.news.entity.category',
            validations: {
              required: true
            },
            defaultValue: 'name',

            templateConfig: {
              autocomplete: {
                dropdown: true,
                field: 'name',
                key: '_id',
                remoteParams: (event, type) => {
                  return [
                    {
                      take: 30,
                      skip: 0,
                      keyword: type === 'search' ? event.query : null
                    },
                    {}
                  ];
                },
                remoteRequest: this._newsRest.findAllCategory.bind(
                  this._newsRest
                ),
                remoteResponseTransform: response => response.data
              }
            },
            fieldEvents: {
              onSelect: (
                value: any,
                field: MFormgenSchemaField,
                form: FormGroup
              ) => {
                form.patchValue({
                  newsCategoryId: value._id
                });
              }
            }
          },
          {
            label: 'Caption',
            name: 'caption',
            field: 'text',
            validations: {
              required: true
            }
          }
        ];
      }
    });
  }

  matchPasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (this.form.get('password').value !== control.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }

  onImageFinishUploaded(image) {
    this.form.patchValue({
      mainImageId: image._id
    });
  }

  onImageDeleted() {
    const mainImageId = this.form.get('mainImageId').value;
    this._newsRest.deleteImageById(mainImageId).subscribe();

    this.form.patchValue({
      mainImageId: null
    });
  }
}

import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { NewsRestService } from '../news-rest.service';

@Component({
  selector: 'app-news-list',
  templateUrl: 'news-list.component.html'
})
export class NewsListComponent extends BaseListBComponent<any> {
  tableColumns: IGridTableColumn[] = [
    {
      i18nLabel: 'ui.news.entity.title',
      field: 'title',
      formatter: (value, row: any) => value
    },
    {
      i18nLabel: 'ui.news.entity.createdDate',
      field: 'createdAt'
    },
    {
      i18nLabel: 'ui.news.entity.category',
      field: 'newsCategory.name'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _newsRest: NewsRestService) {
    super();
    this.componentId = 'NewsListComponent';
    this.headerTitle = 'ui.news.list.title';
    this.exportFileName = 'user';
    this.exportDocumentTitle = 'ui.news.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.user.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.news.list.button.create',
        color: 'primary-outline',
        routerLink: '/news/create'
        // permissions: ['user.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._newsRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM, YYYY');

              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const newsId = event.data._id;
        return this._newsRest.delete(newsId);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'NewsListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}

import { Component } from '@angular/core';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { LocationRestService } from '../location-rest.service';

@Component({
  selector: 'app-location-list',
  templateUrl: 'location-list.component.html'
})
export class LocationListComponent extends BaseListBComponent<any> {
  tableColumns: IGridTableColumn[] = [
    {
      i18nLabel: 'ui.location.entity.category',
      field: 'category'
    },
    {
      i18nLabel: 'ui.location.entity.name',
      field: 'name'
    },
    {
      i18nLabel: 'ui.location.entity.address',
      field: 'address'
    },
    {
      i18nLabel: 'ui.location.entity.latitude',
      field: 'latitude'
    },
    {
      i18nLabel: 'ui.location.entity.longitude',
      field: 'longitude'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _locationRest: LocationRestService) {
    super();
    this.componentId = 'LocationListComponent';
    this.headerTitle = 'ui.location.list.title';
    this.exportFileName = 'location';
    this.exportDocumentTitle = 'ui.location.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.location.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.location.list.button.create',
        color: 'primary-outline',
        routerLink: '/location/create',
        permissions: ['location.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._locationRest.findAll(qParams);
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const locationId = event.data._id;
        return this._locationRest.delete(locationId);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'LocationListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}

import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { ApiQueryOption } from '../../libraries/http/api-query-option.model';
import { RequestService } from '../../libraries/http/request.service';

@Injectable({
  providedIn: 'any'
})
export class PoRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/orders`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(`create`, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  load(PoDetailsComponent: string) {
    return this.request.get<any>(`${PoDetailsComponent}`);
  }

  delete(poId: string) {
    return this.request.delete<any>(`${poId}`);
  }

  update(poId: string, payload: any) {
    return this.request.put<any>(`${poId}`, payload);
  }

  getAccessRole() {
    return this.request.get<any>(`default-role-access`);
  }
}

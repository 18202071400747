import { Component } from '@angular/core';
import { AuthenticationService } from '../../libraries/libraries.module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent { 

  constructor(
    public _authentication: AuthenticationService
  ) { }
}

import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionBackend, HttpModule, XHRBackend } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LayoutsModule } from './layouts/layouts.module';
import { TranslateLoaderService } from './libraries/common/translate-loader.service';
import { LibrariesModule } from './libraries/libraries.module';
import { PROVIDERS as RoleProviders } from './main/role/role.module';
import { PROVIDERS as UserProviders } from './main/user/user.module';
import { PROVIDERS as NewsProviders } from './main/news/news.module';
import { PROVIDERS as FaqProviders } from './main/faq/faq.module';
import { PROVIDERS as NewsCategoriesProviders } from './main/news-categories/news-categories.module';
import { PROVIDERS as LocationProviders } from './main/location/location.module';

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<{}>>(
  'Registered Reducers'
);
export const REDUCERS: ActionReducerMap<{}> = {};

export function HttpLoaderFactory(
  translatorLoaderService: TranslateLoaderService
) {
  return {
    getTranslation(lang: string): Observable<Object> {
      return translatorLoaderService.load(lang);
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    HttpClientModule,
    HttpModule,
    LayoutsModule,
    LibrariesModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    StoreModule.forRoot(REDUCERS_TOKEN),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslateLoaderService]
      }
    })
  ],
  providers: [
    { provide: ConnectionBackend, useClass: XHRBackend },
    { provide: REDUCERS_TOKEN, useValue: REDUCERS },
    ...RoleProviders,
    ...UserProviders,
    ...NewsProviders,
    ...FaqProviders,
    ...NewsCategoriesProviders,
    ...LocationProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from '@angular/core';

@Injectable()
export class DataFormatService {
  constructor() { }

  formatYearRangeReport(): string {
    return `${new Date().getFullYear() - 10} : ${new Date().getFullYear()}`;
  }

  formatYearRangeTransactionMoreFilter(): string {
    return `${new Date().getFullYear() - 10} : ${new Date().getFullYear() + 10}`;
  }

  formatYearRangeBirth(): string {
    return `${new Date().getFullYear() - 100} : ${new Date().getFullYear()}`;
  }

  simpleFormatCurrency(value) {
    return Math.abs(Number(value)) >= 1.0e+12 ?
     // Twelve Zeroes for Trillions
    Math.abs(Number(value)) / 1.0e+12 + " T"

    // Nine Zeroes for Billions
    : Math.abs(Number(value)) >= 1.0e+9 ?
    Math.abs(Number(value)) / 1.0e+9 + " M"

    // Six Zeroes for Millions
    : Math.abs(Number(value)) >= 1.0e+6
    ? Math.abs(Number(value)) / 1.0e+6 + " Jt"

    // Three Zeroes for Thousands
    : Math.abs(Number(value)) >= 1.0e+3
    ? Math.abs(Number(value)) / 1.0e+3 + " Rb"

    : Math.abs(Number(value));
 }
}

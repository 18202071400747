import { Component } from '@angular/core';
import { RoleRestService } from '../role-rest.service';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-role-update',
  templateUrl: 'role-update.component.html'
})
export class RoleUpdateComponent extends BaseUpdateBComponent {
  constructor(private _roleRest: RoleRestService) {
    super();
    this.componentId = 'RoleUpdateComponent';
    this.routeURL = '/roles';
    this.entrySuccessI18n = 'success.role.update';
    this.headerTitle = 'ui.role.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._roleRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._roleRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'RoleFormComponent:workflowLoadRelated',
          'RoleFormComponent:workflowBuildForm',
          'RoleUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}

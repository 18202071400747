import { Observable } from 'rxjs';
import { Constructor } from '../common/common.type';
import { MFieldDefinition, MForm } from '../common/form.model';
import { FormgenTemplateItemBComponent } from './formgen-template-item.bcomponent';
import { FormgenTemplateQuickCreateBComponent } from './formgen-template-quick-create.bcomponent';
import { MFormgenSchema, MFormgenSchemaField } from './formgen.model';

export class MFormgenFieldConfig extends MFieldDefinition {
  label?: string;
  helper?: string;
  fieldEvents?: MFormgenFieldConfigEvents;
  validations?: {
    [key: string]: any;
  };
  interceptEvents?: { [key: string]: boolean };
  templateConfig?: MFormgenFieldConfigTemplateConfig = {
    wrapFormGroupClass: true,
    options: [],
    autocomplete: {
      dropdown: true,
      field: 'value',
      key: 'key',
      localFilter: true,
      limit: false,
      manualInput: false,
      multiple: false,
    },
    checkbox: {
      binary: false,
    },
    textarea: {
      rows: 3,
    },
    calendar: {
      dateFormat: 'dd/mm/yy',
    },
    multiselect: {
      filter: false,
      maxSelectedLabels: 1,
    },
  };
}

export class MFormgenFieldConfigTemplateConfig {
  wrapFormGroupClass?: boolean;
  options?: any;
  styleClass?: string;
  inputStyleClass?: any;
  placeholder?: string;
  type?: string;
  input?: MFormgenFieldConfigTemplateConfigInput;
  autocomplete?: MFormgenFieldConfigTemplateConfigAutocomplete;
  multiselect?: MFormgenFieldConfigTemplateConfigMultiselect;
  checkbox?: MFormgenFieldConfigTemplateConfigCheckbox;
  textarea?: MFormgenFieldConfigTemplateConfigTextarea;
  select?: MFormgenFieldConfigTemplateConfigSelect;
  calendar?: MFormgenFieldConfigTemplateConfigCalendar;
}

export class MFormgenFieldConfigTemplateConfigAutocomplete {
  dropdown?: boolean;
  field?: string;
  itemField?: string;
  itemTemplate?: Constructor<FormgenTemplateItemBComponent<any>>;
  quickCreateEnabled?: boolean;
  quickCreateType?: 'dialog';
  quickCreateTitle?: string;
  quickCreateTemplate?: Constructor<FormgenTemplateQuickCreateBComponent<any>>;
  key?: string;
  localFilter?: boolean;
  localFilterBy?: string | string[];
  manualInput?: boolean;
  remoteUrl?: string;
  remoteParams?: (event: any, type: string, field: MFormgenSchemaField, schema: MFormgenSchema) => any;
  remoteRequest?: (payload?: any, queryParams?: any) => Observable<any>;
  remoteFilterParamKey?: string;
  remoteResponseTransform?: Function;
  remoteLoadFirst?: boolean;
  limit?: any;
  multiple?: boolean;
  appendTo?: 'body';
}

export class MFormgenFieldConfigTemplateConfigCalendar {
  timeOnly?: boolean;
  dateFormat?: string;
}

export class MFormgenFieldConfigTemplateConfigMultiselect {
  optionLabel?: string;
  dataKey?: string;
  inputId?: string;
  filter: boolean;
  maxSelectedLabels: number;
  appendTo?: 'body';
}

export class MFormgenFieldConfigTemplateConfigInput {
  iconPosition?: 'left' | 'right';
  iconClass?: string;
}

export class MFormgenFieldConfigTemplateConfigCheckbox {
  binary?: boolean;
}

export class MFormgenFieldConfigTemplateConfigSelect {
  options: {
    directLabel?: string,
    i18nLabel?: string,
    value: any
  }[];
}

export class MFormgenFieldConfigTemplateConfigTextarea {
  rows?: number;
}

export class MFormgenFieldConfigEvents {
  onAdd?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onRemove?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onChange?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onClear?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onComplete?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onDropdown?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onSelect?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onEnter?: (value, field: MFormgenSchemaField, form: MForm) => void;
  onInit?: (value, field: MFormgenSchemaField, form: MForm) => void;
}

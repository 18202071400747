import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { BasicLayoutComponent } from './layouts/basic/basic-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./main/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./main/dashboard/dashboard.module').then(
            m => m.DashboardModule
          )
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./main/role/role.module').then(m => m.RoleModule)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./main/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'po',
        loadChildren: () => import('./main/po/po.module').then(m => m.PoModule)
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./main/news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'news-categories',
        loadChildren: () =>
          import('./main/news-categories/news-categories.module').then(
            m => m.NewsCategoriesModule
          )
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./main/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'location',
        loadChildren: () =>
          import('./main/location/location.module').then(m => m.LocationModule)
      }
    ]
  }
  // { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'ngx-webstorage';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../libraries/auth/authentication.service';
import { CommonService } from '../../libraries/common/common.service';
import { ApiBootstrapService } from '../../libraries/http/api-bootstrap.service';
import { SpinnerService } from '../../libraries/spinner/spinner.service';
import { SystemMessageService } from '../../libraries/system-message/system-message.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [SystemMessageService]
})
export class LoginComponent implements OnInit {
  @LocalStorage() private rememberMe: string;
  @LocalStorage(null, true) public rememberMeChecked: boolean;

  loginForm: FormGroup;
  unconfirmedUsername: string;
  tokenExpired: boolean;

  constructor(
    public systemMessageService: SystemMessageService,
    @Inject('GlobalSystemMessage') private _globalSystemMessageService: SystemMessageService,
    // private _accountRestService: AccountRestService,
    private _activatedRoute: ActivatedRoute,
    private _apiBootstrapService: ApiBootstrapService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
  ) {
    if (!this._activatedRoute.snapshot.queryParams.fromLogout) {
      this._authenticationService.logout();
    } else {
      this._authenticationService.reset();
    }

    let username = '';
    if (!environment.production) {
      username = 'admin@volta.co.id';
    } else if (this.rememberMe) {
      username = this.rememberMe;
    }

    this.loginForm = this._formBuilder.group({
      'username': [username, [Validators.required]],
      'password': [environment.production ? '' : 'p@ssw0rd', Validators.required]
    });
  }

  ngOnInit() {
    this.tokenExpired = this._activatedRoute.snapshot.queryParams['tokenExpired'];
    if (this.tokenExpired) {
      this._globalSystemMessageService.log({
        message: this._translateService.instant('ui.sessionExpired.error.description'),
        type: 'error',
        showAs: 'growl',
        showSnackBar: false,
      });
    }
  }

  submit(e) {
    if (this.formValid()) {
      const spinner = this._spinnerService.show({
        element: $('.login-box'),
      });

      this.rememberMe = this.rememberMeChecked ? this.loginForm.value.username : null;

      this._authenticationService.login(this.loginForm.value)
        .pipe(
          catchError(error => {
            spinner.dispose();

            this.systemMessageService.log({
              message: error.response.data.message,
              type: 'error'
            });

            return throwError(error);
          })
        )
        .subscribe(user => {
          this._apiBootstrapService.boot(true).pipe(
            first()
          ).subscribe(() => {
            this._authenticationService.redirectAfterLogin().subscribe(() => {
              spinner.dispose();
            });
          });
        });
    }
  }

  formValid(): boolean {
    CommonService.markAsDirty(this.loginForm);
    if (!this.loginForm.valid) {
      this.systemMessageService.log({
        message: this._translateService.instant('error.form'),
        type: 'error'
      });
      return false;
    }
    return true;
  }
}

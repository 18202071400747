import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { MFormgenSchemaField } from '../../../libraries/formgen/formgen.model';
import { RoleRestService } from './../../role/role-rest.service';

@Component({
  selector: 'app-user-form',
  templateUrl: 'user-form.component.html'
})
export class UserFormComponent extends BaseFormBComponent {
  constructor(private _roleRest: RoleRestService) {
    super();

    this.componentId = 'UserFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.user.entity.name',
            name: 'fullName',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            }
          },
          {
            label: 'ui.user.entity.email',
            name: 'email',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            },
            templateConfig: {
              type: 'email'
            }
          },
          {
            label: 'ui.user.entity.role',
            name: 'role',
            field: 'autocomplete',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            },
            templateConfig: {
              autocomplete: {
                dropdown: true,
                field: 'name',
                key: '_id',
                remoteParams: (event, type) => {
                  return [
                    {
                      take: 30,
                      skip: 0,
                      keyword: type === 'search' ? event.query : null
                    },
                    {}
                  ];
                },
                remoteRequest: this._roleRest.findAll.bind(this._roleRest),
                remoteResponseTransform: response => response.data
              }
            },
            fieldEvents: {
              onSelect: (
                value: any,
                field: MFormgenSchemaField,
                form: FormGroup
              ) => {
                form.patchValue({
                  roleId: value._id
                });
              }
            }
          },
          {
            name: 'roleId',
            ignore: true
          },
          {
            label: 'ui.user.entity.password',
            name: 'password',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'reset'
            },
            templateConfig: {
              type: 'password'
            }
          },
          {
            label: 'ui.user.entity.passwordConfirmation',
            name: 'confirmPassword',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'reset'
            },
            templateConfig: {
              type: 'password'
            },
            syncValidators: this.matchPasswords()
          }
        ];
      }
    });
  }

  matchPasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (this.form.get('password').value !== control.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }
}


import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as SnackBar from 'node-snackbar';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MessageParserService } from './message-parser.service';
import { ESystemMessageShowAs, ESystemMessageType, MSystemMessage } from './system-message.model';

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {
  private messages: BehaviorSubject<MSystemMessage[]> = new BehaviorSubject([]);

  constructor(
    private _messageParser: MessageParserService,
    private _translate: TranslateService,
  ) { }

  log({
    message,
    type = 'success',
    showAs = 'block',
    showSnackBar = false,
    parse = true,
    scroll = true,
    timer = null,
    removePreviousMessages = true,
  }) {
    let targetMessages: (string | TemplateRef<any>)[] = _.castArray(message).filter(_.identity);
    type = _.capitalize(type);
    showAs = _.capitalize(showAs);

    if (!targetMessages.length) {
      return;
    }

    if (parse) {
      targetMessages = this._messageParser.parse(targetMessages);
    }

    let nextMessages = this.messages.getValue();

    if (removePreviousMessages) {
      nextMessages = _.filter(nextMessages, systemMessage => systemMessage.options.showAs !== ESystemMessageShowAs[showAs]);
    }

    const newMessageOptions = {
      messages: targetMessages,
      'options.type': ESystemMessageType[type],
      'options.showAs': ESystemMessageShowAs[showAs],
    };
    if (ESystemMessageShowAs[showAs] === ESystemMessageShowAs.Block && timer === null) {
      newMessageOptions['options.timer'] = 0;
    } else if (timer !== null) {
      newMessageOptions['options.timer'] = timer;
    }

    const newMessage = new MSystemMessage(newMessageOptions);
    nextMessages.push(newMessage);

    this.messages.next(nextMessages);

    if (showSnackBar) {
      SnackBar.show({
        text: this._translate.instant('error.unknown'),
        pos: 'top-right'
      });
    }

    if (scroll) {
      $('.app-body').animate({
        scrollTop: $('app-system-message-display').offset().top,
      }, 500);
    }
  }

  getMessages(type?: ESystemMessageType, showAs?: ESystemMessageShowAs | 'all'): Observable<MSystemMessage[]> {
    return this.messages.pipe(
      map(messages => {
        const filters: any = {};
        if (type) {
          _.set(filters, 'options.type', type);
        }
        if (showAs || showAs !== 'all') {
          _.set(filters, 'options.showAs', showAs);
        }
        return _.filter(messages, filters);
      }),
      filter(value => Boolean(_.size(value))),);
  }

  removeMessage(message: MSystemMessage) {
    const messages = this.messages.getValue();
    _.remove(messages, message);

    this.messages.next(messages);
  }
}

export class ApiQueryPaging {
    public take = 10;
    public skip = 0;
    public includeTotalCount: boolean = false;

    constructor() {
    }
}

export class ApiQueryOption extends ApiQueryPaging {
    public sort?: IApiQueryOptionSort[] = new Array<IApiQueryOptionSort>();
    public filter: IApiQueryOptionFilter[] = new Array<IApiQueryOptionFilter>();

    constructor() {
        super();
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { DashboardComponent } from './dashboard.component';

export const routes: Routes = [{
  path: '',
  canActivateChild: [AuthorizationService],
  children: [{
    path: '',
    component: DashboardComponent,
    data: {
      name: 'dashboard',
    },
  }]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class DashboardRoutingModule { }

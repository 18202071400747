import { Component, Output } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { PoRestService } from '../po-rest.service';

@Component({
  selector: 'app-po-details',
  templateUrl: 'po-details.component.html',
  styleUrls: ['./po-details.component.scss']
})
export class PoDetailsComponent extends BaseFormBComponent {
  @Output() _data: any;
  constructor(private _poRest: PoRestService) {
    super();

    this.componentId = 'PoDetailsComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._poRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this._data = res;
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });
    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'PoDetailsComponent:loadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  convertToIdr(value) {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });

    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }
}

import * as _ from 'lodash';
import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field-error',
  templateUrl: 'field-error.component.html',
  styleUrls: ['field-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FieldErrorComponent {
  @Input() control: FormControl;

  get hasError() {
    return Boolean(_.size(_.get(this, 'control.errors', {})));
  }

  get errors() {
    const errors: any = [];
    _.forEach(_.get(this, 'control.errors', {}), (errorData, errorCode) => {
      errors.push({ errorCode, errorData });
    });

    return errors;
  }
}
